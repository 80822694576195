import React from "react";
import fetchMyApi from "../components/services/ApiCalls";

const initialState = {
    answers: [],
    isLoading: false,
    hasError: false,
};

const QuestionnaireContext = React.createContext();

function surveyReducer(state, action) {
    switch (action.type) {
        case 'ADD_ANSWER': {
            return {
                ...state,
                hasError: false,
                answers: state.answers
                    .filter(
                        answer => answer.question !== action.payload.question
                    )
                    .concat(action.payload),
            };
        }
        case 'SUBMIT_SURVEY_PENDING': {
            return { ...state, isLoading: true, hasError: false };
        }
        case 'SUBMIT_SURVEY_SUCCESS': {
            return {
                ...state,
                isLoading: false,
                hasError: false,
                answers: [],
            };
        }
        case 'SUBMIT_SURVEY_FAILURE': {
            return { ...state, isLoading: false, hasError: true };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function SurveyProvider({ children }) {
    const [state, dispatch] = React.useReducer(surveyReducer, initialState);

    const onSurveySubmitted = () => {
        dispatch({ type: 'SUBMIT_SURVEY_SUCCESS' });
    }

    const submitSurvey = async () => {
        dispatch({ type: 'SUBMIT_SURVEY_PENDING' });

        try {
            await fetchMyApi("/api/userresponses/", onSurveySubmitted, "POST", state.answers);
        } catch (error) {
            dispatch({ type: 'SUBMIT_SURVEY_FAILURE' });
        }
    };

    const value = {
        state,
        dispatch,
        submitSurvey,
    };
    return (
        <QuestionnaireContext.Provider value={value}>
            {children}
        </QuestionnaireContext.Provider>
    );
}

function useSurvey() {
    const context = React.useContext(QuestionnaireContext);
    if (context === undefined) {
        throw new Error('useSurvey must be used within a SurveyProvider');
    }
    return context;
}

export { SurveyProvider, useSurvey };
