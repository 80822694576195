import React from 'react';
import {EuiButton, EuiCard, EuiImage} from "@elastic/eui";
import finalImg from "../../../assets/final_illustration.png";

/**
 * A card linking to the final questionnaire.
 *
 * @param disabled - boolean, whether the link is disabled or not
 */
const FinalCard = ({disabled}) => {
    return (
        <EuiCard
            isDisabled={disabled}
            icon={<EuiImage alt={'Final questionnaire'}
                            size={75}
                            src={finalImg} />}
            title="Questionnaire final"
            description="Le questionnaire final est composé de trois questionnaires en lien avec votre perception globale des quatre semaines de suivi à distance."
            footer={
                <div>
                    <EuiButton isDisabled={disabled}
                               href="/questionnaires/final"
                               color={"accent"}
                               aria-label="Aller qu questionnaire final">
                        Répondre aux questions
                    </EuiButton>
                </div>
            }
        />
    );
};

export default FinalCard;
