import React, {
    useEffect, 
    useState,
    useMemo
} from "react";
import {
    formatDate,
    EuiBasicTable,
    EuiTable,
    EuiBadge,
    EuiLink,
    EuiPageTemplate,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer
} from '@elastic/eui';
// import uniqBy from 'lodash/uniqBy';

import fetchMyApi from "../services/ApiCalls";

const renderStatus = status => {
    let color;
    switch(status) {
        case "En cours": {
            color = "success";
            break;
        }
        case "A venir": {
            color = "warning";
            break;
        }
        default: {
            color = "danger";
            break;
        }
    }
    return <EuiBadge color={color}>{status}</EuiBadge>;
}

const columnsDefinition = () => {
    return (
        [{
            field: 'code',
            name: 'Code',
            truncateText: false,
            render: (code) => (
                <EuiLink href={`/dyade/${code}`}>
                    {code}
                </EuiLink>
            ),
        },
        {
            field: 'status',
            name: 'Statut',
            truncateText: false,
            render: (status) => renderStatus(status)
        },
        {
            field: 'start_date',
            name: 'Date de démarrage',
            truncateText: false,
            schema: 'date',
            render: (date) => formatDate(date, 'DD-MM-YYYY, HH:mm')
        },
        {
            field: 'end_date',
            name: 'Date de fin',
            truncateText: false,
            render: (date) => formatDate(date, 'DD-MM-YYYY, HH:mm')
        },
        {
            field: 'aidant',
            name: 'Aidant',
            truncateText: false,
        },
        {
            field: 'patient',
            name: 'Patient',
            truncateText: false,
        }]
    )
}

export const Table = () => {
    const [ dyades, setDyades ] = useState([]);

    const [ sortField, setSortField ] = useState('end_date');
    const [ sortDirection, setSortDirection ] = useState('desc');

    const [ pageIndex, setPageIndex ] = useState(0);
    const [ pageSize, setPageSize ] = useState(5);
    const [ pageOfItems, setPageOfItems ] = useState([])
    const [ totalItemCount, setTotalItemCount ] = useState(0);

    const onTableChange = ({ page = {}, sort = {} }) => {
        const { index: pageIndex, size: pageSize } = page;

        const { field: sortField, direction: sortDirection } = sort;
    
        setPageIndex(pageIndex);
        setPageSize(pageSize);
        setSortField(sortField);
        setSortDirection(sortDirection);

    };

    const sorting = {
        sort: {
            field: sortField,
            direction: sortDirection,
        },
        enableAllColumns: true,
        readOnly: false
    };

    const columns = columnsDefinition();
    
    const pagination = {
        pageIndex,
        pageSize,
        totalItemCount,
        pageSizeOptions: [50, 10, 5],
        showPerPageOptions: true,
    };

    const slicePageOfItems = dyades => {
        const dyadeSlice = dyades.slice(pageIndex*pageSize, pageIndex*pageSize+pageSize);
        setPageOfItems(dyadeSlice);

    }

    useEffect(() => {
        slicePageOfItems(dyades);
    }, [pageIndex, pageSize])

    useEffect(() => {
        if ( dyades.length === 0 ) {
            slicePageOfItems(dyades);
            setTotalItemCount(Object.keys(dyades).length);
        }
    }, [dyades])

    const onDyadesResponse = response => {
        setDyades(response);
    }

    useEffect(() => {
        fetchMyApi("/api/dyades/", onDyadesResponse, "GET");
    }, [])

    const data = useMemo(() => dyades, [dyades]);

  return (
    pageOfItems ? 
        <EuiFlexGroup>
            <EuiFlexItem>
                <EuiBasicTable
                    tableCaption="Demo of EuiBasicTable"
                    items={dyades}
                    columns={columns}
                    pagination={pagination}
                    sorting={sorting}
                    onChange={onTableChange}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
        : <></>
  );
}