import React, {
    useEffect,
    useState,
    useRef
} from "react";

import {
    EuiButton,
    EuiButtonEmpty,
    EuiDatePicker,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiInputPopover
} from '@elastic/eui';
import { useHistory } from "react-router-dom";
import moment from 'moment';

import fetchMyApi from "../services/ApiCalls";
import { validDyadeCode } from "../../models/user";

const MAX_CODE_STRING_LENGTH = 8;

const DyadeCreate = props => {
    const { setIsDyadeCreateVisible, setDyadeDBStatus } = props;
    const { setDyadeCode } = props;

    const [ dyadeCreateCode, setDyadeCreateCode ] = useState("");
    const [ startDate, setStartDate ] = useState(moment());
    const [ isPopoverOpen, setIsPopoverOpen ] = useState(false);

    const history = useHistory();

    const inputRef = useRef();

    const closeModal = () => setIsDyadeCreateVisible(false);

    useEffect(() => {
        if (dyadeCreateCode) {
            setDyadeCode(dyadeCreateCode);
        }
    }, [dyadeCreateCode]);

    const onNewDyadeResponse = response => {
        if ( response?.code[0] === "Un objet dyade avec ce champ code existe déjà." ) {
            setDyadeDBStatus("already exists");
            setIsDyadeCreateVisible(false);
        } else if (response?.detail === "Informations d'authentification non fournies.") {
            setDyadeDBStatus("logged out");
            setIsDyadeCreateVisible(false);
        } else {
            history.push(`/dyade/${dyadeCreateCode}`);
            window.location.reload(true);
        }   
    };

    const toggleIsPopoverOpen = (shouldBeOpen = !isPopoverOpen) => {
        setIsPopoverOpen(shouldBeOpen);
    };

    const handleTextFieldChange = e => {
        let userInput = e.target.value;
        if (userInput.length <= MAX_CODE_STRING_LENGTH) {
            if ( [2, 5].includes(userInput.length) && e.nativeEvent.inputType!=="deleteContentBackward" ) {
                userInput += "-";
                inputRef.current.value = userInput;
            } else if (e.nativeEvent.inputType==="deleteContentBackward") {
                toggleIsPopoverOpen(false);
            }
            setDyadeCreateCode(userInput);
        }
    }

    const input = (
        <EuiFieldText 
            onChange={e => handleTextFieldChange(e)}
            maxLength={MAX_CODE_STRING_LENGTH}
            inputRef={inputRef}
        />);

    const createNewDyade = () => {
        if (validDyadeCode.test(dyadeCreateCode)) {
            let payload = {code: dyadeCreateCode, start_date: startDate};
            fetchMyApi("/api/dyades/create/", onNewDyadeResponse, "POST", payload);
            closeModal();
        } else {
            setIsPopoverOpen(true);
        }
        
    };
    
  

    return (
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Creer une nouvelle dyade</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiForm component="form">
                    <EuiFormRow label="Code d'inclusion">
                        <EuiInputPopover
                            input={input}
                            isOpen={isPopoverOpen}
                        >
                            Format correcte: 22-AQ-ZE)
                        </EuiInputPopover>
                    </EuiFormRow>
                    <EuiFormRow label="date de début">
                        <EuiDatePicker selected={startDate} onChange={date => setStartDate(date)} dateFormat={"DD-MM-YYYY"}/>
                    </EuiFormRow>
                </EuiForm>
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>Annuler</EuiButtonEmpty>

                <EuiButton type="submit" onClick={createNewDyade} fill>
                    Créer
                </EuiButton>
            </EuiModalFooter>

        </EuiModal>
    )

}

export default DyadeCreate;