import regeneratorRuntime from "regenerator-runtime"; // required due to bug (https://github.com/babel/babel/issues/9849)

import getCookie from "./getCookie";

const requestOptionsTemplate = {
    method: 'POST',
    // mode: 'same-origin',
    headers: {
        'Content-Type': 'application/json',
    }
};

const fetchMyApi = async (filePath, callBackFunction=console.log, method="POST", dataPacket={}, grabObjValueByKey="") => {
    let response;
    let requestOptions = {...requestOptionsTemplate};
    var csrftoken = getCookie('csrftoken');
    requestOptions['headers']['X-CSRFToken'] = csrftoken;
    requestOptions['method'] = method;
    if ( method==="POST" ) {
        requestOptions['body'] = JSON.stringify(dataPacket);
        response = await fetch( filePath, requestOptions);
        response = await response.json();
        if (grabObjValueByKey!=="") {
            response = response[grabObjValueByKey]
            console.log("response", response);
        }
    } else {
        response = await fetch( filePath, requestOptions);
        response = await response.json();
    }
    callBackFunction(response);
}

export default fetchMyApi;