import React from 'react';
import {useSurvey} from "../../../store/questionnaires";
import {
    EuiDatePicker,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiNotificationBadge,
    EuiRange,
    EuiSpacer,
    useGeneratedHtmlId,
} from "@elastic/eui";
import emojiScale from '../../../assets/emoji_scale.png';
import Choices from "./Choices";
import moment from "moment";

/**
 * A question with its title and a field to give an answer.
 *
 * @param question - the question to answer
 * @param onAnswerChange - callback when the answer changes
 * @param questionNumber - the position of the question within the questionnaire
 */
const Question = ({
  question,
  onAnswerChange,
  questionNumber,
}
) => {
    const {
        state: { answers },
    } = useSurvey();

    const value = answers.find(a => a.question === question.id)?.value;

    /**
     * Displays a range from 0 to 10.
     * @param withEmoji - boolean, whether to display an emoji scale or not
     */
    const showRange = (withEmoji) => {
        let slider = (<EuiRange
            fullWidth
            min={0}
            max={10}
            name="range"
            value={value === null || value === undefined ? 5 : value}
            onChange={e => onAnswerChange(question.id, e.target.value)}
            id={useGeneratedHtmlId({ prefix: 'formRowRange' })}
            showRange
            showTicks
            ticks={[
                { label: question.minLabel ?? '', value: 0 },
                { label: question.maxLabel ?? '', value: 10 },
            ]}
        />)
        return (
            withEmoji ? (

                <EuiFlexGroup direction={'column'}>
                    <EuiFlexItem>
                        <img src={emojiScale} alt="Emoji scale" />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        {slider}
                    </EuiFlexItem>
                </EuiFlexGroup>
            ) : slider
        );
    };

    /**
     * Displays the relevant component to answer the question, depending on the question type.
     * For example, we return a time picker for a question related to a time.
     */
    const showOptions = () => {
        if (question.answerType === 'range') {
            return showRange();
        }

        if (question.answerType === 'emoji') {
            return showRange(true);
        }

        if (question.answerType === 'choice') {
            return (
                <Choices
                    questionId={question.id}
                    value={value || null}
                    options={question.options}
                    onAnswerChange={onAnswerChange}
                />
            );
        }

        if (question.answerType === 'date') {
            return (
                <EuiDatePicker
                    showTimeSelect
                    showTimeSelectOnly
                    selected={moment('2022-01-01T' + (value ?? '00:00') + ':00')}
                    onChange={handleDateChange}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                />
            );
        }

    }

    /**
     * Callback when the date from the time picker has changed
     * @param date - moment date, the new datetime
     */
    const handleDateChange = date => {
        onAnswerChange(question.id, date.format('HH:mm'))
    }

    return (
        <>
            <EuiFormRow fullWidth label={<span><EuiNotificationBadge>{questionNumber}</EuiNotificationBadge> {question.title}</span>}>
                {question.items ? (<div className={'nested-question'}>
                        {question.items.map((q, i) => (
                            <Question
                                questionNumber={i + 1 + '/' + question.items.length}
                                question={q}
                                key={q.title}
                                onAnswerChange={onAnswerChange}
                            />
                        ))}
                    </div>
                ) : (
                    <>
                        { showOptions() }
                        <EuiSpacer size={'l'}/>
                    </>

                )}

            </EuiFormRow>
            <EuiSpacer size={'l'}/>
        </>

    );
};

export default Question;
