import React, {
    useEffect,
    useState
} from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle
} from "@elastic/eui";

import { useHistory } from "react-router-dom";

/**
 * @param setIsDyadeAlertActive - determines visibility of the modal
 * @param dyadeDBStatus - dyade search status value (e.g. not found, already exists)
 * @param dyadeCode - dyade search value 
 * @returns {<EuiModal>}
 */
const DyadeAlert = props => {
    const { setIsDyadeAlertActive, dyadeDBStatus, dyadeCode } = props;

    const [ modalContentObject, setModalContentObject ] = useState({});

    const closeModal = () => setIsDyadeAlertActive(false);

    const history = useHistory();

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    const fillModalVariables = async ( dyadeCode, dyadeDBStatus, setIsDyadeAlertActive ) => {
        switch(dyadeDBStatus) {
            case "not found":
                setModalContentObject({
                    header: "Dyade pas trouvé!",
                    body: `Le dyade ${dyadeCode} n'a pas été trouvé !`
                });
                break;
            case "already exists":
                setModalContentObject({
                    header: "Dyade déjà existant!",
                    body: `Le dyade ${dyadeCode} éxiste dèjà !`
                });
                break;
            case "logged out":
                setModalContentObject({
                    header: "Vous êtes déconnecté",
                    body: `Veuillez vous reconnecter !`
                });
                await sleep(3000);
                history.push('/user/login/');
                window.location.reload(true);
                break;
            default:
                setIsDyadeAlertActive(false); 
        }
    }

    useEffect(() => {
        fillModalVariables( dyadeCode, dyadeDBStatus, setIsDyadeAlertActive );
    }, [])

    return (

        <EuiModal onClose={closeModal}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>{modalContentObject?.header}</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                {modalContentObject?.body}
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton onClick={closeModal} fill>Fermer</EuiButton>
            </EuiModalFooter>
        </EuiModal>
        
    )
}

export default DyadeAlert;