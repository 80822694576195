import React, {useEffect, useState} from 'react';
import Layout from "../../common/Layout";
import fetchMyApi from "../services/ApiCalls";
import QuestionnairesHeader from "./components/QuestionnairesHeader";

/**
 * The homepage for questionnaires.
 *
 * It allows the user to access the available questionnaires.
 */
const Questionnaires = () => {
    const [ loading, setLoading ] = useState(false);
    const [ routineDisabled, setRoutineDisabled ] = useState(false);
    const [ finalDisabled, setFinalDisabled ] = useState(false);
    const [ inclusionCode, setInclusionCode ] = useState('');

    /**
     * We fetch the inclusion code and the status of the questionnaires. If a questionnaire
     * isn't available, we disable the button to access it.
     */
    useEffect(() => {
        setLoading(true)
        Promise.all([
            fetchMyApi(`/api/users/questionnaire-status/`, handleQuestionnaireStatus, "GET"),
            fetchMyApi(`/api/users/dyade-code/`, setInclusionCode, "GET")
        ]).then(() => setLoading(false));
    }, [])

    /**
     *
     * @param routine - boolean, whether we can answer the routine questionnaire or not
     * @param final - object, whether we can answer each 3 sections of the final questionnaire or not
     *
     * @example routine: `true`
     * @example final: `{ 1: false, 2: true, 3: true }`
     */
    const handleQuestionnaireStatus = ({ routine, final }) => {
        setRoutineDisabled(!routine)

        const isDisabled = (section) => !section
        const hasAllFinalSectionsDisabled = Object.values(final).every(isDisabled);
        setFinalDisabled(hasAllFinalSectionsDisabled);
    }

    return (
        <Layout title={""}
                restrictWidth={'80%'}
                headerContent={<QuestionnairesHeader
                    inclusionCode={inclusionCode}
                    loading={loading}
                    routineDisabled={routineDisabled}
                    finalDisabled={finalDisabled}
                />}
        >
        </Layout>
        );
};

export default Questionnaires;
