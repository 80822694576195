import React, {useEffect, useState} from 'react';
import {EuiCallOut, EuiLoadingSpinner, EuiSpacer, EuiText} from "@elastic/eui";
import {useSurvey} from "../../store/questionnaires";
import {useHistory} from "react-router-dom";
import fetchMyApi from "../services/ApiCalls";
import HorizontalSteps from "../../common/horizontal-steps/HorizontalSteps";
import SectionQuestions from "../routine-questionnaire/components/SectionQuestions";
import Layout from "../../common/Layout";

/**+
 * Information section giving details about the questionnaire.
 */
const QuestionnaireDescription = ({ description }) => (
    <EuiCallOut
        title="Vous allez compléter le questionnaire final de l'étude."
        iconType="search"
    >
        <EuiText size="s">
            {description.split('\\n').map((paragraph, i) => (
                <div key={i}><p>{paragraph}</p><EuiSpacer size={'s'}/></div>
            ))}
        </EuiText>

    </EuiCallOut>
);

/**
 * The page displaying the final questionnaire, i.e. a set of three questionnaires to answer at the end
 * of the study.
 */
const FinalQuestionnaire = () => {
    const [questionnaire, setQuestionnaire] = useState(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ currentPosition, setCurrentPosition ] = useState(0);
    const {
        submitSurvey,
        dispatch,
    } = useSurvey();
    let history = useHistory();

    /**
     * Fetch the questions on init
     */
    useEffect(() => {
        setLoading(true)
        fetchMyApi(`/api/questionnaire/final`, setQuestionnaire, "GET")
            .then(() => setLoading(false));
    }, [])

    /**
     * Save the given value for the given question
     * @param id - the question id
     * @param value - the answer of the question
     */
    const saveAnswerValue = (id, value) => {
        dispatch({
            type: 'ADD_ANSWER',
            payload: {
                question: id,
                value,
                timestamp: Date.now(),
                questionnaire: 'final',
            },
        });
    }

    /**
     * Get the status of the given step depending on the position of the currently selected step.
     * @param stepIndex - the position of the step
     * @returns {string} - the status of the step
     */
    const getStepStatus = stepIndex => {
        if (stepIndex === currentPosition) return 'current';
        if (stepIndex < currentPosition) return 'complete';
        if (stepIndex > currentPosition) return 'incomplete';
    }

    const steps = questionnaire?.questions ?? []

    const icons = [ 'faceNeutral', 'analyzeEvent', 'heart']

    const horizontalSteps = steps.map((step, i) => ({
        title: step.title,
        status: getStepStatus(i),
        icon: icons[i]
    }));

    /**
     * Handles the click on the 'Next' button at the end of each questions section.
     */
    const handleNextStep = async () => {
        if (isLastStep) {
            await submitSurvey();
            history.push('/')
        }
        else setCurrentPosition(currentPosition + 1);
    }

    const lastPosition = steps.length - 1;
    const isLastStep = currentPosition === lastPosition;


    return (
        <Layout title={"Questionnaire final"}
                restrictWidth={'80%'}
                headerContent={<QuestionnaireDescription description={questionnaire?.description ?? "Aucune description disponible"}/>}
        >
            {
                loading ? <EuiLoadingSpinner size="xl" /> : (
                    <>
                        <HorizontalSteps steps={horizontalSteps} />

                        {
                            steps.length ? (
                                <>
                                    <EuiSpacer />
                                    <SectionQuestions onNextClick={handleNextStep}
                                                      onAnswerChange={saveAnswerValue}
                                                      questions={steps[currentPosition].items}
                                                      isLastStep={isLastStep}
                                                      isLarge={true}
                                                      hasDefaultAnswers={false}
                                    />
                                </>
                            ) : null
                        }

                    </>

                )
            }
        </Layout>
    );
};

export default FinalQuestionnaire;
