import React, {
    useState,
    useEffect,
    useRef
} from "react";

import {
    EuiButtonIcon,
    EuiFieldSearch,
} from '@elastic/eui';

import { useHistory } from "react-router-dom";

import fetchMyApi from "../services/ApiCalls";

const SearchDyade = props => {
    const { setDyadeDBStatus, setDyadeCode } = props;

    const [ dyadeSearchCode, setDyadeSearchCode ] = useState('');

    const history = useHistory();

    const searchFieldRef = useRef();

    useEffect(() => {
        if ( dyadeSearchCode ) {
            setDyadeCode(dyadeSearchCode);
            fetchMyApi(`/api/dyades/${dyadeSearchCode}`, onDyadeResponse, "GET");
        }
    }, [dyadeSearchCode])

    const onDyadeResponse = dyadeResponse => {
        if ( dyadeResponse?.detail==="Pas trouvé." ) {
            setDyadeDBStatus("not found");
            setDyadeSearchCode("");
        } else if (dyadeResponse?.detail==="Informations d'authentification non fournies.") {
            setDyadeDBStatus("logged out");
            setDyadeSearchCode("");
        } else {
            history.push(`/dyade/${dyadeSearchCode}`);
            window.location.reload(true);
        }
    }

    const searchDyade = e => {
        e && setDyadeSearchCode(e);
    }

    const clickSearchDyade = () => {
        searchFieldRef.current.state.value && setDyadeSearchCode(searchFieldRef.current.state.value);
    }

    return (
        <>
            <EuiFieldSearch
                placeholder="dyade par code"
                isClearable={true}
                onSearch={searchDyade}
                ref={searchFieldRef}
            />
            <EuiButtonIcon 
                iconType="lensApp"
                size={"m"}
                aria-label="Lens"
                onClick={clickSearchDyade}
                color={"primary"}
            />
        </>
    )  
}

export default SearchDyade;