import React, {
    useEffect,
    useState
} from "react";

import { 
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiStat
} from "@elastic/eui";

import fetchMyApi from "../services/ApiCalls";

/**
 * queries endpoint and then returns a list of Eui stats to be rendered
 * @param {*} props 
 * @returns { [<EuiStat>] }
 */
const Stats = props => {
    const [ statisticsArray, setStatisticsArray ] = useState([]);
    const [ statisticsResponse, setStatisticsResponse ] = useState([]);

    const colorArray = ['primary', 'subdued', 'success', 'danger', 'accent'];

    const createStatistics = statistics => {
        let statisticsReturn = [];
        statistics.forEach(statisticObject => {
            let randomIndex = Math.floor(Math.random() * colorArray.length);
            let randomColor = colorArray[randomIndex];
            const stat = Object.keys(statisticObject).map(key => {
                return (
                    <EuiFlexItem key={key + randomColor}>
                        <EuiPanel key={"_" + statisticObject[key] + randomColor}>
                            <EuiStat 
                                title={statisticObject[key]}
                                description={key}
                                key={key + "_" + statisticObject[key] + randomColor}
                                titleColor={randomColor}
                                titleSize={"s"}
                            /> 
                        </EuiPanel>
                    </EuiFlexItem> 
                )  
            })
            statisticsReturn.push(stat);
        });
        return statisticsReturn;
    }


    useEffect(() => {
        if (statisticsResponse.length) {
           setStatisticsArray(createStatistics(statisticsResponse));
        }
    }, [statisticsResponse])


    useEffect(() => {
        fetchMyApi('/api/study/statistics/', setStatisticsResponse, 'GET');
    }, [])
    
    
    return  (
        <EuiFlexGroup>
            {statisticsArray}
        </EuiFlexGroup>
    )
}

export default Stats;