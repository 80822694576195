import React from 'react';
import {EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer} from "@elastic/eui";

/**
 * A component allowing the user to choose a value from a list of options.
 *
 * @param onAnswerChange - function, callback when a new value is selected
 * @param id - number, the question id
 * @param value - string, the current selected choice
 * @param options - array of {label, value}, the different options to choose from
 */
const Choices = ({ onAnswerChange, questionId, value, options }) => {

    return (
        <EuiFlexGroup
            key={value}
            gutterSize="s"
            alignItems="center"
            responsive={false}
            wrap
        >
            {options.map((option, i) => (
                <EuiFlexItem grow={false} key={i}>
                    <EuiButton
                        size="s"
                        color={'text'}
                        fill={option.value === value}
                        onClick={_ => onAnswerChange(questionId, option.value)}
                    >
                        {option.label}
                    </EuiButton>
                </EuiFlexItem>
            ))}
        </EuiFlexGroup>
    );
};

export default Choices;
