import React from 'react';

import { EUI_CHARTS_THEME_LIGHT } from "@elastic/eui/dist/eui_charts_theme";
import {
    Chart,
    Axis,
    BarSeries,
    Settings
} from '@elastic/charts';

const Calender = props => {
    const { fakeArray } = props;

    return (
        
        <Chart size={{ height: 200 }}>
            <Settings />
            <BarSeries
                id="status"
                name="Status"
                data={fakeArray}
                xAccessor="x"
                yAccessors={["y"]}
                splitSeriesAccessors={["g"]}
            />
            <Axis
                id='top-axis'
                position='top'
                title='Questionnaires routines complétés'
            />
            <Axis 
                id="bottom-axis"
                position="bottom"
                title="Jour"
            />
        </Chart>

    );
};

export default Calender;