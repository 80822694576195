import React from 'react';
import {EuiCallOut, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiImage, EuiSpacer} from "@elastic/eui";
import logoLight from "../../../assets/logo_light.png";
import RoutineCard from "./RoutineCard";
import FinalCard from "./FinalCard";

/**
 * The header of the questionnaires homepage.
 *
 * It displays links to the routine and final questionnaires.
 *
 * @param routineDisabled - boolean, whether to disable the link to the routine questionnaire or not
 * @param finalDisabled - boolean, whether to disable the link to the final questionnaire or not
 * @param loading - boolean, whether data is being fetched or not
 * @param inclusionCode - the user's inclusion code in the study
 */
const QuestionnairesHeader = ({routineDisabled, finalDisabled, loading, inclusionCode}) => {
    return (
        <EuiEmptyPrompt
            icon={<EuiImage alt={'Questionnaires'}
                            size="s"
                            src={logoLight} />}
            title={<h2>Bonjour, {inclusionCode}</h2>}
            body={<p>N’oubliez pas de brancher les bracelets à votre ordinateur pour synchroniser les données !</p>}
            actions={
                <>
                    <EuiSpacer />

                    {
                        (routineDisabled && finalDisabled) ? <>
                            <EuiCallOut title="Merci pour vos réponses ! Vous avez répondu à tous les questionnaires disponibles." color="success" iconType="cheer">
                            </EuiCallOut>
                            <EuiSpacer />
                        </> : null
                    }

                    <EuiFlexGroup gutterSize="l">
                        <EuiFlexItem>
                            <RoutineCard disabled={routineDisabled} />
                        </EuiFlexItem>

                        <EuiFlexItem>
                            <FinalCard disabled={finalDisabled} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </>
            }
        />
    );
};

export default QuestionnairesHeader;
