import React from 'react';
import classNames from 'classnames';
import Step from "./Step";

/**
 * The individual step composing the horizontal steps bar.
 * Inspired by EuiHorizontalStep, and allows to customize the icon within the step indicator.
 */
const HorizontalStep = ({
     className,
     step = 1,
     title,
     icon,
     isSelected,
     isComplete,
     onClick,
     disabled,
     status = 'incomplete',
     ...rest
 }) => {
    if (disabled) status = 'disabled';
    else if (isComplete) status = 'complete';
    else if (isSelected) status = 'current';

    const classes = classNames('euiStepHorizontal', className, {
        'euiStepHorizontal-isSelected': status === 'current',
        'euiStepHorizontal-isComplete': status === 'complete',
        'euiStepHorizontal-isIncomplete': status === 'incomplete',
        'euiStepHorizontal-isDisabled': status === 'disabled',
    });

    return (
        <div
            className={classes}
            title={title}
            {...rest}
        >
            <Step
                className="euiStepHorizontal__number"
                icon={icon}
                status={status}
                number={step}
            />

            <span className="euiStepHorizontal__title">{title}</span>
        </div>
    );
};

export default HorizontalStep;
