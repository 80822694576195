import React, {
    useEffect, 
    useRef, 
    useState
} from "react";

import {
    EuiAccordion,
    EuiButton,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiComboBox,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiDescriptionList,
    EuiDatePickerRange,
    EuiDatePicker,
    EuiPanel,
} from "@elastic/eui";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import fetchMyApi from "../services/ApiCalls";
import Layout from "../../common/Layout"
import Calender from "./Calender";



const DownloadCSVPanel = props => {
    const { dyadeDetailObj } = props;

    const [ userResponsesData, setUserResponsesData ] = useState([]);
    const [ isDownloadModalVisible, setIsDownloadModalVisible ] = useState(false);
    const [ userResponseDates, setUserResponseDates ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);

    const [ selectedDate, setSelectedDate ] = useState([]);
    const [ startDate, setStartDate ] = useState(moment());
    const [ endDate, setEndDate ] = useState(moment().add(11, 'd'));
    const [ defaultStart, setDefaultStart ] = useState();
    const [ defaultEnd, setDefaultEnd ] = useState();

    const [ dateParams, setDateParams ] = useState("");

    const [ isDownloadActive, setIsDownloadActive ] = useState(true);
    const [ comboAccordianTrigger, setComboAccordianTrigger ] = useState('closed');
    const [ rangeAccordianTrigger, setRangeAccordianTrigger ] = useState('closed');


    
    const triggerAPIFetch = () => {
        fetchMyApi(`/api/userresponses/download?username=${dyadeDetailObj?.aidant}` + dateParams, setUserResponsesData, "GET")
    }

    const fillComboBox = () => {
        let aidant_questionnaires_answered_detail = dyadeDetailObj?.aidant_questionnaires_answered_detail;
        let dates = [];
        Object.keys(aidant_questionnaires_answered_detail).map(key => {
            dates.push({label: aidant_questionnaires_answered_detail[key]});
        });
        setUserResponseDates([...dates]);
    }

    useEffect(() => {
        if ( endDate !== defaultEnd && startDate !== defaultStart ) {
            setDateParams(`&start_date=${startDate.format("YYYY-MM-DD")}&end_date=${endDate.format("YYYY-MM-DD")}`);
            setSelectedDate([]);
        }
    }, [endDate, startDate])

    useEffect(() => {
        console.log("selectedDate", selectedDate); 
        if (selectedDate.length) {
            setDateParams(`&date=${selectedDate[0]?.label}`);
        } else if (endDate === defaultEnd && startDate === defaultStart ) {
            setDateParams("");
        };
    }, [selectedDate])

    const closeModal = () => setIsDownloadModalVisible(false);

    useEffect(() => {
        if (userResponsesData.length) {
            setIsDownloadModalVisible(true);
        }
    }, [userResponsesData]);

    useEffect(() => {
        if (userResponseDates) {
            setIsLoading(false);
        }
    }, [userResponseDates])

    useEffect(() => {
        fillComboBox();
        setDefaultStart(moment(dyadeDetailObj?.aidant_questionnaires_answered_detail.at(-1)));
        setDefaultEnd(moment(dyadeDetailObj?.aidant_questionnaires_answered_detail.at(-1)));
        setStartDate(moment(dyadeDetailObj?.aidant_questionnaires_answered_detail.at(-1)));
        setEndDate(moment(dyadeDetailObj?.aidant_questionnaires_answered_detail.at(0)));
    }, [])


    const onChange = selectedDateChange => {
        setSelectedDate(selectedDateChange);
    };

    const onToggleRange = isOpen => {
        setIsDownloadActive(!isOpen);
        if (isOpen) {
            setRangeAccordianTrigger('open');
            comboAccordianTrigger=='open' && setComboAccordianTrigger('closed');
        } else {
            setRangeAccordianTrigger('close');
        }
    };

    const onToggleCombo = isOpen => {
        setIsDownloadActive(!isOpen);
        if (isOpen) {
            setComboAccordianTrigger('open')
            rangeAccordianTrigger=='open' && setRangeAccordianTrigger('closed');
        } else {
            setComboAccordianTrigger('close');
        }
    };

    return (
        <EuiFlexGroup >
            <EuiFlexItem grow={4}>
                {isLoading ? <></> :
                    <EuiAccordion
                        onToggle={onToggleCombo}
                        buttonContent="Par date précise"
                        forceState={comboAccordianTrigger}
                    >
                        <EuiFormRow >
                            <EuiComboBox 
                                options={userResponseDates.length ? userResponseDates : []}
                                selectedOptions={selectedDate}
                                onChange={onChange}
                                singleSelection
                            />
                        </EuiFormRow>
                    </EuiAccordion>
                }
            </EuiFlexItem>

            <EuiFlexItem>
                
            </EuiFlexItem>
            <EuiFlexItem grow={6}>
                <EuiAccordion
                    onToggle={onToggleRange}
                    buttonContent="Par plage des dates"
                    forceState={rangeAccordianTrigger}
                >
                    <EuiFormRow>
                        <EuiDatePickerRange
                            startDateControl={
                                <EuiDatePicker
                                    selected={startDate}
                                    onChange={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    isInvalid={startDate > endDate}
                                    aria-label="Start date"
                                    showTimeSelect
                                />
                            }
                            endDateControl={
                                <EuiDatePicker
                                    selected={endDate}
                                    onChange={setEndDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    isInvalid={startDate > endDate}
                                    aria-label="End date"
                                    showTimeSelect
                                />
                            }
                        />
                    </EuiFormRow>
                </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiFormRow hasEmptyLabelSpace>
                    <EuiButton 
                        onClick={triggerAPIFetch}
                        isDisabled={isDownloadActive}
                    >
                        OK
                    </EuiButton>
                </EuiFormRow>
            </EuiFlexItem>
            {isDownloadModalVisible ?
                <EuiModal onClose={closeModal}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h2>Téléchargement</h2>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <CSVLink 
                            data={userResponsesData}
                            filename={`${dyadeDetailObj?.aidant}.csv`}
                            className="euiButton euiButton--primary btn"
                            target={"_blank"}>
                        fichier csv
                        </CSVLink>
                    </EuiModalBody>
                </EuiModal> : <></>
            }
        </EuiFlexGroup>

        
    )

}

const DyadeDescription = props => {
    const { dyadeDetailObj } = props;

    const loadDescriptionList = () => {
        let description = [
            <EuiFlexItem>
                <EuiDescriptionList 
                    textStyle="reverse"
                    listItems={ [{ title: "aidant", description: dyadeDetailObj?.aidant }]}
                    compressed
                />
            </EuiFlexItem>,
            <EuiFlexItem>
                <EuiDescriptionList 
                    textStyle="reverse"
                    listItems={ [{ title: "patient", description: dyadeDetailObj?.patient  }]}
                    compressed
                />
            </EuiFlexItem>,
            <EuiFlexItem>
                <EuiDescriptionList 
                    textStyle="reverse"
                    listItems={ [{ title: "date de démarrage", description: moment(dyadeDetailObj?.start_date).format('LL') }]}
                    compressed
                />
            </EuiFlexItem>,
            <EuiFlexItem>
                <EuiDescriptionList 
                    textStyle="reverse"
                    listItems={[ { title: "date de fin", description: moment(dyadeDetailObj?.end_date).format('LL') }]}
                    compressed
                />
            </EuiFlexItem>,
            <EuiFlexItem>
                <EuiDescriptionList 
                    textStyle="reverse"
                    listItems={ [{title: "statut", description: dyadeDetailObj?.status }]}
                    compressed
                />
            </EuiFlexItem>,
            <EuiFlexItem>
                <EuiDescriptionList 
                    textStyle="reverse"
                    listItems={ [{ title: "dernier mise à jour ", description: moment(dyadeDetailObj?.last_update).format('LL') }]}
                    compressed
                />
            </EuiFlexItem>,
        ];
        
        return description;
    }

    return loadDescriptionList()
}

export const DyadeView = () => {
    let { code } = useParams();

    const [ dyadeDetailObj, setDyadeDetailObj ] = useState({});
    const [ fakeArray, setFakeArray ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        if (fakeArray) {
            setIsLoading(false);
        }
    }, [fakeArray])

    const fillFakeArray = questionnaires_answered_days_date => {
        var tempFakeArray = [];
        for (let index = 1; index < 29; index++) {
            let tempObj = { 
                x: index,
                y: questionnaires_answered_days_date[index] ? 1 : 0,
                g: questionnaires_answered_days_date[index] || ""
            };
            tempFakeArray.push(tempObj);
        }
        setFakeArray(tempFakeArray);
    }

    useEffect(() => {
        if ( Object.keys(dyadeDetailObj).length ) {
            fillFakeArray(dyadeDetailObj?.questionnaires_answered_days_date);
        }
    }, [dyadeDetailObj])

    useEffect(() => {
        setIsLoading(true);
        fetchMyApi(`/api/dyades/detail/${code}`, setDyadeDetailObj, 'GET');
    }, [])

    return(
        !isLoading ? 
            <Layout
                restrictWidth={'100%'}
                id={"dyade-view-header"}
                noHeader
            >
                <EuiPanel>
                    <EuiFlexGroup>
                        <EuiFlexItem grow={3}>
                            <h3>{`Dyade ${code}`}</h3>
                        </EuiFlexItem> 
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                        <DyadeDescription dyadeDetailObj={dyadeDetailObj} />
                    </EuiFlexGroup>
                </EuiPanel>
                <EuiSpacer size="s"/>
                <EuiPanel>
                    <EuiFlexGroup>
                        <EuiFlexItem justifyContent>
                            <h3>Téléchargement</h3>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    {Object.keys(dyadeDetailObj).length ? 
                    <DownloadCSVPanel dyadeDetailObj={dyadeDetailObj}/> :
                    <></>}
                </EuiPanel>
                <EuiSpacer size="s"/>
                <EuiPanel>
                    <EuiFlexGroup>
                        <EuiFlexItem justifyContent>
                            <h3>Calendrier des questionnaires routines</h3>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <Calender 
                                fakeArray={fakeArray}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
                <EuiSpacer size="s"/>
                <EuiPanel>
                    <EuiFlexGroup>
                        <EuiFlexItem justifyContent>
                            <h3>Questionnaire final</h3>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            {dyadeDetailObj?.aidant_questionnaire_status?.final[3] ? "à compléter" : "Complété"}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            </Layout>
        : <></>
    )
}