import React from 'react';

import {
    EuiPage,
    EuiPageContent,
    EuiPageHeader,
    EuiPageBody,
    EuiPageContentBody,
} from '@elastic/eui';

/**
 * Default layout for a page.
 *
 * @param buttons - array,  buttons to display in the header
 * @param title - string, page title
 * @param description - string, page description
 * @param children - jsx element, the content of the page
 * @param headerContent - jsx element, the header of the page
 * @param noHeader - boolean, whether to display an header or not
 * @param icon - jsx element, the page icon
 * @param restrictWidth - string, the max width of the page
 */
export default ({ buttons = [], title, description, children, headerContent = <></>, noHeader = false, icon, restrictWidth, id }) => (
    <EuiPage paddingSize="none">
        <EuiPageBody>
            {noHeader ? null : <EuiPageHeader
                restrictWidth={restrictWidth || true}
                paddingSize={'l'}
                pageTitle={title}
                description={description}
                rightSideItems={buttons}
                iconType={icon}
                iconProps={{size: 'xxl'}}
                id={id}
            >
                {headerContent}
            </EuiPageHeader>}
            <EuiPageContent borderRadius="none" hasShadow={true} paddingSize="none">
                {children ? <EuiPageContentBody restrictWidth={restrictWidth || true} paddingSize="l">
                    {children}
                </EuiPageContentBody> : null}
            </EuiPageContent>
        </EuiPageBody>
    </EuiPage>
);
