import React from 'react';
import {EuiButton, EuiCard, EuiImage} from "@elastic/eui";
import routineImg from "../../../assets/routine_illustration.svg";

/**
 * A card linking to the routine questionnaire.
 *
 * @param disabled - boolean, whether the link is disabled or not
 */
const RoutineCard = ({disabled}) => {
    return (
        <EuiCard
            isDisabled={disabled}
            icon={<EuiImage alt={'Routine questionnaire'}
                            size="m"
                            src={routineImg} />}
            title="Questionnaire de routine"
            description="Le compte-rendu sur la journée choisie commence au coucher la veille au soir et doit se faire au fur et à mesure à chaque moment d’intérêt : coucher, lever, petit-déjeuner, déjeuner et dîner."
            footer={
                <div>
                    <EuiButton isDisabled={disabled}
                               href="/questionnaires/routine"
                               color={"accent"}
                               aria-label="Aller au questionnaire de routine">
                        Répondre aux questions
                    </EuiButton>
                </div>
            }
        />
    );
};

export default RoutineCard;
