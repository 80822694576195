import React, {useEffect, useState} from 'react';
import Layout from "../../common/Layout";
import {EuiCallOut, EuiText, EuiSpacer, EuiLoadingSpinner} from "@elastic/eui";
import fetchMyApi from "../services/ApiCalls";
import HorizontalSteps from "../../common/horizontal-steps/HorizontalSteps";
import coucher from '../../assets/icons/coucher.svg';
import lever from '../../assets/icons/lever.svg';
import petitDejeuner from '../../assets/icons/petit_dejeuner.svg';
import dejeuner from '../../assets/icons/dejeuner.svg';
import diner from '../../assets/icons/diner.svg';
import global from '../../assets/icons/journee_globale.svg';
import SectionQuestions from "./components/SectionQuestions";
import {useSurvey} from "../../store/questionnaires";
import { useHistory } from "react-router-dom";

/**+
 * Information section giving details about the questionnaire.
 */
const QuestionnaireDescription = ({ description }) => (
    <EuiCallOut
        title="Vous allez compléter le questionnaire de routine."
        iconType="search"
    >
        <EuiText size="s">
            {description.split('\\n').map((paragraph, i) => (
                <div key={i}><p>{paragraph}</p><EuiSpacer size={'s'}/></div>
            ))}
        </EuiText>

    </EuiCallOut>
);

/**
 * The page displaying the routine questionnaire, i.e. six sections that the user
 * has to answer every 5 days.
 */
const RoutineQuestionnaire = () => {
    const [questionnaire, setQuestionnaire] = useState(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ currentPosition, setCurrentPosition ] = useState(0);
    const {
        submitSurvey,
        dispatch,
    } = useSurvey();
    let history = useHistory();


    /**
     * Fetch the questions on init
     */
    useEffect(() => {
        setLoading(true)
        fetchMyApi(`/api/questionnaire/routine`, handleQuestionnaire, "GET")
            .then(() => setLoading(false));
    }, [])

    const handleQuestionnaire = (survey) => {
        initializeDefaultAnswers(survey);
        setQuestionnaire(survey);
    }

    /**
     * Save the default value for a 'range' kind of question
     * @param id - the question id
     */
    const saveDefaultRange = id => saveAnswerValue(id, '5');

    /**
     * Save the default value for a 'time' kind of question
     * @param id - the question id
     */
    const saveDefaultTime = id => saveAnswerValue(id, '00:00');

    /**
     * Save the given value for the given question
     * @param id - the question id
     * @param value - the answer of the question
     */
    const saveAnswerValue = (id, value) => {
        dispatch({
            type: 'ADD_ANSWER',
            payload: {
                question: id,
                value,
                timestamp: Date.now(),
                questionnaire: 'routine',
            },
        });
    }

    /**
     * Every question has a default answer, so we need to persist them
     * in case the user doesn't update the value of the question.
     * @param survey - the routine questionnaire
     */
    const initializeDefaultAnswers = (survey) => {
        const steps = survey.questions;
        steps.forEach((step) => {
            step.items.forEach((question) => {
                if (
                    question?.answerType === 'emoji' ||
                    question?.answerType === 'range'
                ) {
                    saveDefaultRange(question.id);
                } else if (question?.answerType === 'date') {
                    saveDefaultTime(question.id);
                }
                if (question?.items) {
                    question.items.forEach((q) => {
                        if (q?.answerType === 'date') {
                            saveDefaultTime(q.id);
                        }
                    });
                }
            });
        });

    }

    /**
     * Get the status of the given step depending on the position of the currently selected step.
     * @param stepIndex - the position of the step
     * @returns {string} - the status of the step
     */
    const getStepStatus = stepIndex => {
        if (stepIndex === currentPosition) return 'current';
        if (stepIndex < currentPosition) return 'complete';
        if (stepIndex > currentPosition) return 'incomplete';
    }

    const steps = questionnaire?.questions ?? []

    const icons = [ coucher, lever, petitDejeuner, dejeuner, diner, global]

    const horizontalSteps = steps.map((step, i) => ({
        title: step.title,
        status: getStepStatus(i),
        icon: icons[i]
    }));

    /**
     * Handles the click on the 'Next' button at the end of each questions section.
     */
    const handleNextStep = async () => {
        if (isLastStep) {
            await submitSurvey();
            history.push('/')
        }
        else setCurrentPosition(currentPosition + 1);
    }

    const lastPosition = steps.length - 1;
    const isLastStep = currentPosition === lastPosition;

    console.log('questionnaire => ', questionnaire)

    return (
        <Layout title={"Questionnaire de routine"}
                restrictWidth={'80%'}
                headerContent={<QuestionnaireDescription description={questionnaire?.description ?? "Aucune description disponible"}/>}
        >
            {
                loading ? <EuiLoadingSpinner size="xl" /> : (
                    <>
                        <HorizontalSteps steps={horizontalSteps} />

                        {
                            steps.length ? (
                                <>
                                    <EuiSpacer />
                                    <SectionQuestions onNextClick={handleNextStep}
                                                      onAnswerChange={saveAnswerValue}
                                                      questions={steps[currentPosition].items}
                                                      isLastStep={isLastStep}
                                                      hasDefaultAnswers={true}
                                    />
                                </>
                            ) : null
                        }

                    </>

                )
            }
        </Layout>
    );
};

export default RoutineQuestionnaire;
