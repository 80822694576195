import React from 'react';
import {EuiEmptyPrompt, EuiPageTemplate} from "@elastic/eui";

/**
 * A page displaying an "Access denied" message.
 */
const AccessDenied = () => {
    return (
        <EuiPageTemplate
            className={"full-height"}
            template="centeredBody"
            pageContentProps={{ paddingSize: 'none' }}
        >
            <EuiEmptyPrompt
                title={<span>Accès interdit</span>}
                body={<span>Vous n'avez pas les droits nécessaires pour accéder à cette ressource.</span>}
            />
        </EuiPageTemplate>
    );
};

export default AccessDenied;
