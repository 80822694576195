import React from 'react';
import { EuiPageTemplate, EuiEmptyPrompt, EuiLoadingSpinner } from '@elastic/eui';

/**
 * A page displaying a loading message.
 */
const LoadingPage = () => {
    return (
        <div className={"full-height"}>
            <EuiPageTemplate
                template="centeredBody"
                pageContentProps={{ paddingSize: 'none' }}
            >
                <EuiEmptyPrompt
                    icon={<EuiLoadingSpinner size="xl" />}
                    title={<h2>Chargement d'Ecocapture@home...</h2>}
                />
            </EuiPageTemplate>
        </div>

    );
};

export default LoadingPage;

