import React, {useEffect, useState} from 'react';
import Question from "./Question";
import {EuiForm, EuiFlexGroup, EuiFlexItem, EuiButton} from "@elastic/eui";
import _ from "lodash";

/**
 * A section, i.e. a group of questions.
 *
 * @param questions - the questions to display
 * @param onAnswerChange - callback when an answer has changed
 * @param onNextClick - callback when the user clicks on the 'Next' button
 * @param isLastStep - whether there is no more steps to display or not
 * @param isLarge - whether the form needs a lot of width or not
 * @param hasDefaultAnswers - whether the questionnaire has default values or not. If it has default values,
 * we enable the 'Submit/Next' button by default because every question already has a default answer. If there are no
 * default answers to questions, we have to count to number of answers selected by the user and enable the 'Submit/Next'
 * button only when all questions have been answered.
 */
const SectionQuestions = ({questions, onAnswerChange, onNextClick, isLastStep, isLarge, hasDefaultAnswers}) => {
    const [ answeredQuestions, setAnsweredQuestions ] = useState([]);

    /**
     * Whenever we get a new list of questions, we reinitialize the answered questions array
     */
    useEffect(() => {
        setAnsweredQuestions([]);
    }, [questions])

    /**
     * Handles a new answer given by the user. It updates the count of answered questions,
     * which is used to enable the 'Next' button (the user can move to the next section only
     * when all the questions have been answered).
     * @param id - id of the question
     * @param answer - the value of the answer
     */
    const handleAnswerChange = (id, answer) => {
        onAnswerChange(id, answer);
        // We remove duplicates from the answered questions array in case the user answers
        // the same question multiple times
        setAnsweredQuestions(_.uniq([...answeredQuestions, id]));
    }

    /**
     * Gets the number of questions in the section (the questions can be nested).
     * We compute this number to know if the user has answered all the questions or not.
     * @param items - a list of questions
     * @returns {*} - the number of questions in the given list
     */
    const getNbQuestions = items => {
        return items.reduce((acc, val) => {
            return acc + (val.items ? getNbQuestions(val.items) : 1)
        }, 0)
    }

    const nbQuestions = getNbQuestions(questions)
    const nextButtonEnabled = hasDefaultAnswers || (nbQuestions === answeredQuestions.length)

    return <EuiFlexGroup justifyContent="spaceAround">
        <EuiFlexItem grow={false}>
            <EuiForm component="form" className={isLarge ? 'form-large' : 'form'}>
                {questions.map((question, index) => (
                    <Question
                        questionNumber={
                            index + 1 + '/' + questions.length
                        }
                        key={question.title}
                        question={question}
                        onAnswerChange={handleAnswerChange}
                    />

                ))
                }

                <EuiButton isDisabled={!nextButtonEnabled} onClick={onNextClick} fill>
                    {isLastStep ? 'Envoyer' : 'Suivant'}
                </EuiButton>
            </EuiForm>
        </EuiFlexItem>
    </EuiFlexGroup>;
};

export default SectionQuestions;
