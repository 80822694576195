import React, { useState, useEffect } from "react";

import {
    EuiHeader,
    EuiHeaderSection,
    EuiHeaderSectionItem,
    EuiHeaderLogo,
    EuiHeaderLinks,
    EuiHeaderLink,
    EuiFieldSearch,
    EuiButton
} from '@elastic/eui';

import icon from "../../assets/logo_light.png";
import { Link, useHistory } from "react-router-dom";

import DyadeCreate from "../dyade/DyadeCreate"
import DyadeAlert from "../../common/DyadeAlert";
import SearchDyade from "./SearchDyade"
import {ROLE_RESEARCHER} from "../../models/user";

const Header = props => {
    const { userRole } = props;

    const [ isDyadeCreateVisible, setIsDyadeCreateVisible ] = useState(false);
    const [ isDyadeAlertActive, setIsDyadeAlertActive ] = useState(false);
    const [ dyadeDBStatus, setDyadeDBStatus ] = useState('');
    const [ dyadeCode, setDyadeCode ] = useState('');



    const createNewDyade = () => {
        setIsDyadeCreateVisible(true);
    }

    useEffect(() => {
        if ( dyadeDBStatus !== "" && dyadeCode!=="" ) {
            setIsDyadeAlertActive(true);
        }
    }, [dyadeDBStatus, dyadeCode])

    useEffect(() => {
        if (!isDyadeAlertActive) {
            setDyadeDBStatus("");
        }
    }, [isDyadeAlertActive])    

    const canAccessDashboard = userRole === ROLE_RESEARCHER;

    useEffect(() => {
        if ( !isDyadeAlertActive ) {
            setDyadeDBStatus("");
        }
    }, [isDyadeAlertActive])

    return (
        <EuiHeader>
            
            <EuiHeaderSectionItem>
                <EuiHeaderLogo className={'logo'} iconType={icon} href="/">Ecocapture</EuiHeaderLogo>
            </EuiHeaderSectionItem>

            { canAccessDashboard ?
                <>
                    <EuiHeaderSection side={"left"}>
                        <EuiHeaderSectionItem>
                            <SearchDyade
                                setDyadeDBStatus={setDyadeDBStatus}
                                setDyadeCode={setDyadeCode}
                            />
                            
                        </EuiHeaderSectionItem>
                    </EuiHeaderSection>
                    { isDyadeAlertActive ? 
                        <DyadeAlert
                            setIsDyadeAlertActive={setIsDyadeAlertActive}
                            dyadeDBStatus={dyadeDBStatus}
                            dyadeCode={dyadeCode}
                        /> : <></> }
                    <EuiHeaderSection>
                        <EuiHeaderSectionItem side={"right"}>
                            <EuiButton onClick={createNewDyade}>Creer une nouvelle dyade</EuiButton>
                            { isDyadeCreateVisible ? 
                                <DyadeCreate 
                                    setIsDyadeCreateVisible={setIsDyadeCreateVisible} 
                                    setDyadeDBStatus={setDyadeDBStatus}
                                    setDyadeCode={setDyadeCode}
                                /> : <></> }
                        </EuiHeaderSectionItem>
                    </EuiHeaderSection>
                    

                    <EuiHeaderSectionItem border={"none"}>
                        <EuiHeaderLinks aria-label="App navigation links">
                            <Link to="/">
                                <EuiHeaderLink isActive={location.pathname === '/'}>Tableau de bord</EuiHeaderLink>
                            </Link>
                            <EuiHeaderLink iconType={"popout"} iconSide={"right"} target={"_blank"}
                                           href={"/admin/"}>Administration</EuiHeaderLink>

                            <EuiHeaderLink iconType={'push'} iconSide={"right"} href={"/user/logout/"}>Déconnexion</EuiHeaderLink>
                        </EuiHeaderLinks>
                    </EuiHeaderSectionItem>


                </>
                : (<EuiHeaderSectionItem border={"none"}>
                    <EuiHeaderLinks aria-label="App navigation links">
                        <EuiHeaderLink iconType={'push'} iconSide={"right"} href={"/user/logout/"}>Déconnexion</EuiHeaderLink>
                    </EuiHeaderLinks>
                </EuiHeaderSectionItem>) }
        </EuiHeader>
    )
}

export default Header;
