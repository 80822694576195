import React, {
    useEffect,
    useState
} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import Header from '../header/Header';
import { Dashboard } from '../dashboard/Dashboard';
import { DyadeView } from '../dyade/DyadeView';
import {ROLE_AIDANT, ROLE_RESEARCHER} from "../../models/user";
import AccessDenied from "../../common/AccessDenied";
import LoadingPage from "../../common/LoadingPage";
import fetchMyApi from "../services/ApiCalls";
import Questionnaires from "../questionnaire-homepage/Questionnaires";
import RoutineQuestionnaire from "../routine-questionnaire/RoutineQuestionnaire";
import FinalQuestionnaire from "../final-questionnaire/FinalQuestionnaire";
import {SurveyProvider} from "../../store/questionnaires";

/**
 * Entrypoint of the application. It routes the user to the relevant pages, depending on their privileges.
 * For instance, a researcher can access the dashboard but a patient cannot.
 */
const App = () => {
    const [ userRole, setUserRole ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ routineEnabled, setRoutineEnabled ] = useState(false);
    const [ finalEnabled, setFinalEnabled ] = useState(false);

    /**
     * We fetch the user role (researcher, aidant...) to deny access to the pages they don't have the privilege to see.
     */
    useEffect(() => {
        setLoading(true)
        Promise.allSettled([
            fetchMyApi(`/api/users/role/`, setUserRole, "GET"),
            fetchMyApi(`/api/users/questionnaire-status/`, handleQuestionnaireStatus, "GET"),
        ]).then(() => setLoading(false));
    }, [])

    /**
     *
     * @param routine - boolean, whether we can answer the routine questionnaire or not
     * @param final - object, whether we can answer each 3 sections of the final questionnaire or not
     *
     * @example routine: `true`
     * @example final: `{ 1: false, 2: true, 3: true }`
     */
    const handleQuestionnaireStatus = ({ routine, final }) => {
        setRoutineEnabled(routine)

        const isEnabled = (section) => section
        const hasAllFinalSectionsEnabled = Object.values(final).every(isEnabled);
        setFinalEnabled(hasAllFinalSectionsEnabled);
    }

    // Whether they can see the dashboard, create dyade etc
    const hasDashboardAccess = userRole === ROLE_RESEARCHER
    // Whether they can see and answer questionnaires
    const hasQuestionnaireAccess = userRole === ROLE_AIDANT
    // Whether they have access to this Ecocapture web app (for using the dashboard or answering questionnaires)
    const hasWebAppAccess = hasDashboardAccess || hasQuestionnaireAccess

    return loading ? <LoadingPage /> : (
        hasWebAppAccess ?
        (<Router id="root">
            <div>
                <Header
                    userRole={userRole}
                />
                <Switch>
                    <Route path="/dyade/:code">
                        {hasDashboardAccess ?  <DyadeView/> : <AccessDenied />}
                    </Route>
                    <Route path="/dyade/create">
                        {hasDashboardAccess ? <></> : <AccessDenied />}
                    </Route>
                    <Route exact path="/">
                        {hasDashboardAccess ?  <Dashboard/> : (hasQuestionnaireAccess ? <Questionnaires /> : <AccessDenied />)}
                    </Route>
                    <Route path="/questionnaires/routine">
                        <SurveyProvider>
                            {(hasQuestionnaireAccess && routineEnabled) ? <RoutineQuestionnaire /> : <AccessDenied />}
                        </SurveyProvider>

                    </Route>

                    <Route path="/questionnaires/final">
                        <SurveyProvider>
                            {(hasQuestionnaireAccess && finalEnabled) ? <FinalQuestionnaire /> : <AccessDenied />}
                        </SurveyProvider>
                    </Route>
                </Switch>
            </div>
        </Router> ) : <AccessDenied />
    )
};

export default App;
