import React from 'react';
import classNames from 'classnames';
import HorizontalStep from "./HorizontalStep";

/**
 * A component displaying horizontal steps.
 * Inspired by EuiHorizontalSteps, and allows to customize the icon within the step indicator
 * (which is not possible with EuiHorizontalSteps).
 */
const HorizontalSteps = ({
     className,
     steps,
     ...rest
 }) => {
    const classes = classNames('euiStepsHorizontal', className);
    return (
        <ol className={classes} {...rest}>
            {steps.map((stepProps, index) => {
                const isCurrent =
                    stepProps.isSelected || stepProps.status === 'current'
                        ? { 'aria-current': 'step' }
                        : {};

                return (
                    <li key={index} className="euiStepHorizontal__item" {...isCurrent}>
                        <HorizontalStep step={index + 1} {...stepProps} />
                    </li>
                );
            })}
        </ol>
    );
};

export default HorizontalSteps;
