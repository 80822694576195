import React from 'react';
import classNames from 'classnames';
import {EuiIcon} from "@elastic/eui";

const Step = ({
  className,
  status,
  number,
  icon,
  isHollow,
  titleSize,
  ...rest
}) => {

    const statusToClassNameMap = {
        incomplete: 'euiStepNumber--incomplete',
        disabled: 'euiStepNumber--disabled',
        loading: 'euiStepNumber--loading',
        warning: 'euiStepNumber--warning',
        danger: 'euiStepNumber--danger',
        complete: 'euiStepNumber--complete',
        current: null, // Current displays the same as the default (undefined)
    };

    const classes = classNames(
        'euiStepNumber',
        status ? statusToClassNameMap[status] : undefined,
        { 'euiStepNumber-isHollow': isHollow },
        className
    );

    return (
        <span className={classes} {...rest}>
      <EuiIcon
          type={icon}
          className="euiStepNumber__icon"
          size={"m"}
          aria-label={"Step"}
      />

    </span>
    );
};

export default Step;
