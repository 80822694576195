import React from "react";

import { Table } from "./Table";
import Stats from "./Stats";
import Layout from "../../common/Layout";

export const Dashboard = () => {

    return (
        <Layout
                restrictWidth={'100%'}
                headerContent={<Stats />}
                id={"dashboard-header"}
        >
            <Table />
        </Layout>
    )
}